<template>
  <v-app>
    <component :is="layoutComponent">
      <v-main>
        <router-view :key="$route.fullPath" />
        <notifications />
        <error-message />
      </v-main>
    </component>
  </v-app>
</template>

<script>
import Default from "@/components/layouts/Default";
import NoSidebar from "@/components/layouts/NoSidebar";
import Notifications from "@/components/layout/notifications/Notifications.vue";
import ErrorMessage from "@/components/layout/notifications/ErrorMessage";
export default {
  name: "App",
  components: {
    Default,
    NoSidebar,
    Notifications,
    ErrorMessage
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    layoutComponent() {
      return this.$route.meta.layout ?? "default";
    }
  }
};
</script>

<style lang="scss">
@import "../node_modules/typeface-roboto/index.css";
.col-2-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.5rem;
}
</style>
