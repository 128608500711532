<template>
  <v-alert
    elevation="6"
    class="notification"
    width="400"
    border="left"
    colored-border
    color="primary"
    icon="mdi-information-outline"
    prominent
  >
    {{ notificationToShow | translate }}
  </v-alert>
</template>

<script>
export default {
  name: "Notification",
  props: {
    notificationToShow: {
      type: String
    }
  },
  emits: ["end-notification"],
  data() {
    return {
      duration: 4000
    };
  },
  mounted() {
    setTimeout(() => {
      this.$emit("end-notification");
    }, this.duration);
  }
};
</script>

<style scoped>
.notification {
  margin-top: 0.5em;
  position: fixed;
  bottom: 1rem;
  left: calc(50vw - 200px);
}
</style>
