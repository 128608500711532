<template>
  <v-card>
    <v-card-title>{{ this.getProductNameUppercase() }}</v-card-title>
    <v-container>
      <p>Aantal beschikbaar: {{ availableLicenses.length }}</p>
      <p>
        Aantal vergrendeld: {{ lockedLicenses.length }}
        <v-btn icon x-small @click="lockedLicensesDialog.show = true">
          <v-icon small>mdi-open-in-new</v-icon>
        </v-btn>
      </p>
      <!--<v-btn color="primary" text right @click="openAssignmentDialog" :disabled="!(availableLicenses.length > 0)">Toewijzen</v-btn>-->
      <v-btn
        text
        color="primary"
        v-if="isAdmin"
        @click="newLicenseDialog.show = true"
        :disabled="!prerequisitesMet"
        >Nieuwe licentie</v-btn
      >
    </v-container>
    <v-dialog width="600" v-model="dialog.show">
      <v-sheet>
        <v-container>
          <h1 class="headline">Licentie toewijzen</h1>
          <information-block>
            <template v-slot:title>Licentie toewijzen</template>
            <template v-slot:content>
              Via dit scherm kunt u een licentie toewijzen aan een gebruiker.
              Zodra u een licentie heeft toegewezen, is deze gedurende 3 maanden
              niet meer los te koppelen.
            </template>
          </information-block>
          <v-form>
            <v-select
              :items="availableUsers"
              label="Gebruiker"
              v-model="dialog.input.selectedUser"
            >
              <template v-slot:item="{ item }">
                {{ item.first_name }} {{ item.last_name }}
              </template>
              <template v-slot:selection="{ item }">
                {{ item.first_name }} {{ item.last_name }}
              </template>
            </v-select>
            <v-btn
              color="primary"
              @click="assignLicense"
              :disabled="!dialog.input.selectedUser"
              :loading="dialog.loading"
              >Toewijzen</v-btn
            >
          </v-form>
        </v-container>
      </v-sheet>
    </v-dialog>
    <v-dialog v-model="newLicenseDialog.show" width="600">
      <new-irc-license-form
        @licenseCreated="licenseCreated"
        v-if="product === 'irc'"
      />
      <new-web-access-license-form
        @licenseCreated="licenseCreated"
        v-if="product === 'webaccess'"
      />
      <new-otk-license-form
        @licenseCreated="licenseCreated"
        v-else-if="product === 'otk'"
      />
      <new-darkweb-license-form
        @licenseCreated="licenseCreated"
        v-else-if="product === 'darkweb'"
      />
      <NewNetShiftLicenseForm
        @licenseCreated="licenseCreated"
        v-else-if="product === 'netshift'"
      />
    </v-dialog>
    <v-dialog v-model="lockedLicensesDialog.show" width="600">
      <v-sheet>
        <v-toolbar dark color="teal">
          <v-toolbar-title>Verlopen licenties</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            elevation="3"
            style="margin-right: .5rem"
            @click="lockedLicensesDialog.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-data-table
          :items="lockedLicenses"
          :headers="lockedLicensesDialog.headers"
        >
          <template v-slot:item.locked_until="{ item }">{{
            item.locked_until | formatDate
          }}</template>
        </v-data-table>
      </v-sheet>
    </v-dialog>
  </v-card>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import NewIRCLicenseForm from "@/components/Licenses/LicenseCreation/NewIRCLicenseForm";
import NewOTKLicenseForm from "@/components/Licenses/LicenseCreation/NewOTKLicenseForm";
import NewDarkwebLicenseForm from "@/components/Licenses/LicenseCreation/NewDarkwebLicenseForm";
import NewNetShiftLicenseForm from "@/components/Licenses/LicenseCreation/NewNetShiftLicenseForm";
import NewWebAccessLicenseForm from "@/components/Licenses/LicenseCreation/NewWebAccessLicenseForm";
export default {
  name: "LicenseHandler",
  props: {
    product: {
      type: String,
      required: true
    },
    licenses: {
      type: Array,
      required: true
    },
    users: {
      type: Array
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    prerequisitesMet: {
      type: Boolean,
      default: true
    }
  },
  components: {
    "new-irc-license-form": NewIRCLicenseForm,
    "new-otk-license-form": NewOTKLicenseForm,
    NewDarkwebLicenseForm,
    NewWebAccessLicenseForm,
    NewNetShiftLicenseForm
  },
  data() {
    return {
      dialog: {
        show: false,
        loading: false,
        input: {
          selectedUser: undefined
        }
      },
      newLicenseDialog: {
        show: false
      },
      lockedLicensesDialog: {
        show: false,
        headers: [
          {
            text: "ID",
            value: "id"
          },
          {
            text: "Vergrendeld tot",
            value: "locked_until"
          }
        ]
      }
    };
  },
  methods: {
    getProductNameUppercase() {
      switch (this.product) {
        case "irc":
          return "iRC";
        case "otk":
          return "OTK";
        case "darkweb":
          return "Darkweb";
        case "webaccess":
          return "WebAccess";
        case "netshift":
          return "NetShift";
        default:
          return undefined;
      }
    },
    openAssignmentDialog() {
      this.dialog.show = true;
    },
    assignLicense() {
      let license = this.availableLicenses[0];
      license.user_id = this.dialog.input.selectedUser.id;
      this.dialog.loading = true;
      this.licenseRepository
        .update(license, license.id)
        .then(res => {
          this.$emit("licenseUpdated", res.data.data);
          this.dialog.show = false;
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.licenses.assigned"
          );
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
        })
        .finally(() => {
          this.dialog.loading = false;
        });
    },
    licenseCreated() {
      this.$store.dispatch(
        "notifications/addFeedbackNotification",
        "feedback.licenses.created"
      );
      this.$emit("licenseUpdated");
      this.newLicenseDialog.show = false;
    }
  },
  computed: {
    availableLicenses() {
      return this.licenses.filter(
        el =>
          el.user_id === null &&
          (!el.locked_until || new Date(el.locked_until) < new Date())
      );
    },
    lockedLicenses() {
      return this.licenses.filter(
        el =>
          el.user_id === null &&
          !!el.locked_until &&
          new Date(el.locked_until) > new Date()
      );
    },
    licenseRepository() {
      switch (this.product) {
        case "irc":
          return RepositoryFactory.get("ircLicenses");
        case "otk":
          return RepositoryFactory.get("otkLicenses");
        case "darkweb":
          return RepositoryFactory.get("darkwebLicenses");
        case "webaccess":
          return RepositoryFactory.get("webAccessLicenses");
        default:
          return undefined;
      }
    },
    availableUsers() {
      return this.users.filter(el =>
        el.licenses.every(license => license.product !== this.product)
      );
    }
  }
};
</script>

<style scoped></style>
