import AuthRepository from "@/repositories/AuthRepository";
import OrganisationRepository from "@/repositories/OrganisationRepository";
import InvitationRepository from "@/repositories/InvitationRepository";
import IRCLicenseRepository from "@/repositories/Licenses/IRCLicenseRepository";
import OTKLicenseRepository from "@/repositories/Licenses/OTKLicenseRepository";
import DarkwebLicenseRepository from "@/repositories/Licenses/DarkwebLicenseRepository";
import NetShiftLicenseRepository from "@/repositories/Licenses/NetShiftLicenseRepository";
import UserRepository from "@/repositories/UserRepository";
import IRCLoggingRepository from "@/repositories/Logging/IRCLoggingRepository";
import SharedLogRepository from "@/repositories/Logging/SharedLogRepository";
import OTKRepository from "@/repositories/Products/OTKRepository";
import BillingRepository from "@/repositories/Billing/BillingRepository";
import IRCRepository from "@/repositories/Products/IRCRepository";
import IRCStateRepository from "@/repositories/Products/IRCStateRepository";
import NewsItemRepository from "@/repositories/NewsItemRepository";
import WebAccessLicenseRepository from "./Licenses/WebAccessLicenseRepository";
import SalesRepository from "./Sales/SalesRepository";
import SupportRepository from "@/repositories/Support/SupportRepository";
import UserCourseRepository from "@/repositories/Courses/UserCourseRepository";
import PendingUserRepository from "@/repositories/PendingUserRepository";
import TrialOrderRepository from "@/repositories/Trial/TrialOrderRepository";
import TrialAccountRepository from "@/repositories/Trial/TrialAccountRepository";

const repositories = {
  auth: AuthRepository,
  users: UserRepository,
  organisations: OrganisationRepository,
  invite: InvitationRepository,
  ircLicenses: IRCLicenseRepository,
  otkLicenses: OTKLicenseRepository,
  darkwebLicenses: DarkwebLicenseRepository,
  webAccessLicenses: WebAccessLicenseRepository,
  netShiftLicenses: NetShiftLicenseRepository,
  ircLogging: IRCLoggingRepository,
  sharedLogs: SharedLogRepository,
  otkProduct: OTKRepository,
  ircProduct: IRCRepository,
  ircState: IRCStateRepository,
  billing: BillingRepository,
  sales: SalesRepository,
  newsItems: NewsItemRepository,
  support: SupportRepository,
  userCourses: UserCourseRepository,
  pendingUsers: PendingUserRepository,
  trialOrders: TrialOrderRepository,
  trialAccounts: TrialAccountRepository
};

export default {
  get: name => repositories[name]
};
