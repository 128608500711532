<template>
  <v-card class="mx-auto" outlined elevation="3">
    <v-list-item three-line>
      <v-list-item-content>
        <v-list-item-title class="text-h5 mb-1 text-center">
          {{ this.getProductName() }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-card-actions class="justify-center">
      <div v-if="this.conditionalRendering">
        <v-btn text color="primary" disabled>
          <span v-if="this.license">
            Licentie intrekken
          </span>
          <span v-else>
            Licentie aanvragen
          </span>
        </v-btn>
      </div>

      <div v-else-if="!this.license">
        <v-btn text color="primary" @click="openLicenseAssignDialog">
          Licentie toewijzen
        </v-btn>
      </div>

      <div v-else-if="this.license">
        <v-btn text color="error" @click="openLicenseRevokeDialog">
          Licentie intrekken
        </v-btn>
      </div>
    </v-card-actions>

    <v-dialog v-model="revokeDialog.show" width="600">
      <v-card max-width="700" class="mx-auto">
        <v-toolbar color="teal" dark>
          <v-icon class="toolbar-title-icon">mdi-card-remove</v-icon>
          <v-toolbar-title>Licentie intrekken</v-toolbar-title>
        </v-toolbar>
        <v-sheet>
          <v-container>
            <p>
              U staat op het punt om de
              <span class="productNameSpan">{{ this.getProductName() }}</span>
              licentie te herroepen.
            </p>
            <p>Weet u dit zeker?</p>
            <v-btn
              @click="revokeLicense"
              class="mr-2"
              :loading="revokeDialog.loading"
              color="primary"
              >Bevestigen
            </v-btn>
            <v-btn color="error" @click="revokeDialog.show = false"
              >Annuleren
            </v-btn>
          </v-container>
        </v-sheet>
      </v-card>
    </v-dialog>

    <v-dialog v-model="assignDialog.show" width="600">
      <v-card max-width="700" class="mx-auto">
        <v-toolbar color="teal" dark>
          <v-icon class="toolbar-title-icon">mdi-card-plus</v-icon>
          <v-toolbar-title>Licentie toewijzen</v-toolbar-title>
        </v-toolbar>
        <v-sheet>
          <v-container>
            <p>
              U staat op het punt om de
              <span class="productNameSpan">{{ this.getProductName() }}</span>
              licentie toe te wijzen.
            </p>
            <p>Weet u dit zeker?</p>
            <v-btn
              @click="assignLicense"
              class="mr-2"
              :loading="assignDialog.loading"
              color="primary"
              >Bevestigen
            </v-btn>
            <v-btn color="error" @click="assignDialog.show = false"
              >Annuleren
            </v-btn>
          </v-container>
        </v-sheet>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  name: "LicenseActionCard",

  data() {
    return {
      revokeDialog: {
        show: false,
        loading: false
      },
      assignDialog: {
        show: false,
        loading: false
      }
    };
  },

  props: {
    license: Object,
    licenses: Array,
    ircLicense: Object,
    webaccessLicense: Object,
    otkLicense: Object,
    darkwebLicense: Object,
    user: Object,
    product: String,
    isAdmin: Boolean
  },

  computed: {
    availableLicenses() {
      return this.licenses.filter(
        el =>
          !el.user_id &&
          (!el.locked_until || new Date(el.locked_until) < Date.now())
      );
    },
    licenseRepository() {
      switch (this.product) {
        case "irc":
          return RepositoryFactory.get("ircLicenses");
        case "otk":
          return RepositoryFactory.get("otkLicenses");
        case "darkweb":
          return RepositoryFactory.get("darkwebLicenses");
        case "webaccess":
          return RepositoryFactory.get("webAccessLicenses");
        case "netshift":
          return RepositoryFactory.get("netShiftLicenses");
        default:
          return undefined;
      }
    },

    conditionalRendering() {
      if (!this.user.initial_login) return true;
      if (this.product === "darkweb" && !this.ircLicense) return true;
      if (this.product === "darkweb" && !this.otkLicense) return true;

      if (this.product === "otk" && !this.ircLicense) return true;

      if (this.product === "webaccess" && !this.ircLicense) return true;

      if (this.product === "irc" && this.webaccessLicense) return true;
      if (this.product === "irc" && this.otkLicense) return true;
      if (this.product === "irc" && this.darkwebLicense) return true;

      if (this.product === "netshift" && !this.ircLicense) return true;

      return false;
    }
  },

  methods: {
    getProductName() {
      switch (this.product) {
        case "irc":
          return "iRC";
        case "otk":
          return "OTK";
        case "darkweb":
          return "Darkweb";
        case "webaccess":
          return "WebAccess";
        case "netshift":
          return "NetShift";
        default:
          return undefined;
      }
    },
    assignLicense() {
      let currentLicense = this.availableLicenses[0];
      if (!currentLicense) {
        console.log("No license available");
        this.$store.dispatch(
          "notifications/addFeedbackNotification",
          "feedback.licenses.noLicenseAvailable"
        );
        this.assignDialog.show = false;
        this.$emit("close-dialog");
        return;
      }

      currentLicense.user_id = this.user.id;
      this.assignDialog.loading = true;
      this.licenseRepository
        .update(currentLicense, currentLicense.id)
        .then(res => {
          this.$emit("licenseUpdated", res.data.data);

          this.assignDialog.show = false;
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.licenses.assigned"
          );
          this.$emit("close-dialog");
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
        })
        .finally(() => {
          this.assignDialog.loading = false;
        });
    },
    revokeLicense() {
      if (!this.license) return;
      let data = this.license;
      data.user_id = null;
      this.revokeDialog.loading = true;
      this.licenseRepository
        .update(data, this.license.id)
        .then(() => {
          this.$store.dispatch("organisations/loadOrganisations");
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.licenses.revoked"
          );
        })
        .catch(err => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            err.response.data.message || "error.generic"
          );
        })
        .finally(() => {
          this.revokeDialog.loading = false;
          this.revokeDialog.show = false;
          this.$emit("close-dialog");
        });
    },
    openLicenseRevokeDialog() {
      this.revokeDialog.show = true;
    },
    openLicenseAssignDialog() {
      this.assignDialog.show = true;
    }
  }
};
</script>

<style scoped></style>
