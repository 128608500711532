<template>
  <v-slide-y-reverse-transition>
    <notification
      v-if="notificationToShow && !switching.status"
      :notification-to-show="notificationToShow"
      @end-notification="removeNotification"
    ></notification>
  </v-slide-y-reverse-transition>
</template>

<script>
import Notification from "@/components/layout/notifications/Notification.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "Notifications",
  components: {
    Notification
  },
  data() {
    return {
      switching: {
        status: false,
        duration: 200
      }
    };
  },
  methods: {
    ...mapMutations("notifications", ["clearFirstFeedbackNotification"]),
    removeNotification() {
      this.switching.status = true;
      this.clearFirstFeedbackNotification();

      setTimeout(() => {
        this.switching.status = false;
      }, this.switching.duration);
    }
  },
  computed: {
    ...mapGetters("notifications", ["feedbackNotifications"]),
    notificationToShow() {
      return this.feedbackNotifications.length > 0
        ? this.feedbackNotifications[0]
        : null;
    }
  }
};
</script>

<style scoped></style>
