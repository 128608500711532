<template>
  <v-form v-model="form.valid">
    <v-card max-width="700" class="mx-auto">
      <v-toolbar color="teal" dark>
        <v-icon class="toolbar-title-icon">mdi-card-plus</v-icon>
        <v-toolbar-title>Nieuwe OTK licentie</v-toolbar-title>
      </v-toolbar>
      <v-sheet>
        <v-container>
          <v-text-field
            label="Aantal"
            :rules="form.rules.amount"
            v-model="form.amount"
          />
          <v-text-field
            prefix="€"
            v-model="form.input.balance"
            label="Start tegoed"
            :rules="form.rules.balance"
          />
          <v-combobox
            label="Modules"
            :items="form.modules"
            v-model="form.input.modules"
            multiple
          />
          <v-menu min-width="auto">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                label="Verloopdatum"
                prepend-icon="mdi-calendar"
                readonly
                :value="form.input.expirationDate"
              />
            </template>
            <v-date-picker
              v-model="form.input.expirationDate"
              :min="new Date().toISOString().slice(0, 10)"
            />
          </v-menu>
          <v-btn
            color="primary"
            :loading="form.loading"
            :disabled="!form.valid"
            @click="createNewLicense"
          >Aanmaken
          </v-btn
          >
        </v-container>
      </v-sheet>
    </v-card>
  </v-form>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  name: "NewOTKLicenseForm",
  data() {
    return {
      form: {
        valid: false,
        rules: {
          balance: [
            v => !!(v || v === 0) || "Veld is verplicht",
            v => !isNaN(v) || "Tegoed moet een getal zijn",
            v => !(v < 0) || "Tegoed moet groter dan 0 zijn"
          ],
          amount: [
            v => !!v || "Veld is verplicht",
            v => !isNaN(v) || "Voer een getal in",
            v => Number.isInteger(Number(v)) || "Voer een geheel getal in",
            v => v > 0 || "Voer een getal groter dan 0 in"
          ]
        },
        input: {
          balance: 0,
          expiration_date: undefined,
          modules: []
        },
        amount: 1,
        loading: false,
        modules: ["Betaalmodules"]
      }
    };
  },
  methods: {
    createNewLicense() {
      this.form.loading = true;
      let orgId = this.$route.params["organisation"];

      RepositoryFactory.get("otkLicenses")
        .store({
          expiration_date: this.form.input.expirationDate,
          organisation_id: orgId,
          amount: this.form.amount,
          meta: {
            balance: this.form.input.balance,
            modules: this.form.input.modules
          }
        })
        .then(() => {
          this.$emit("licenseCreated");
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/setErrorMessage",
            "feedback.licenses.error"
          );
        })
        .finally(() => (this.form.loading = false));
    }
  },
  computed: {
    oneYear() {
      let nextYear = new Date();
      nextYear.setFullYear(nextYear.getFullYear() + 1);
      return nextYear.toISOString();
    }
  }
};
</script>

<style scoped>

</style>
